/*eslint-disable*/
import React from "react";
// react components for routing our app without refresh
import { Link } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Hidden from "@material-ui/core/Hidden";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";


import styles from "assets/jss/material-kit-react/components/headerLinksStyle.js";
import { Button } from "@material-ui/core";

const useStyles = makeStyles(styles);
import config from '../../config/config';

export default function HeaderLinks(props) {
  const classes = useStyles();
  return (
    <div className="home_page_menu">
      <List className={classes.list + " main_navbar"}>
        <ListItem className={classes.listItem}>
          <a href="https://operonorigins.com/staking/#whatisstaking" target="_blank" color="transparent" className="nav-link">WHAT IS STAKING?</a>
        </ListItem> 
        <ListItem className={classes.listItem}>
          <div className="dropdown">
            <a className="nav-link dropdown-toggle btn03" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              Stake Now
            </a>
            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <Link to="/farms" color="transparent" className="dropdown-item">Stake LP</Link>
              <Link to="/Pools" color="transparent" className="dropdown-item">Stake ORO</Link>
            </div>
          </div>
      </ListItem>
      </List>
    </div>
  );
}
